import Img from 'gatsby-image'
import React from 'react'
interface GroupMemberProps {
  name: string
  quote: string
  role: string
  description: string
  color: string
  imgFixed: any
}

const classes = /** class={ */ {
  member: 'mx-auto w-60 py-8',
  memberImg: 'mx-auto w-48',
  memberNameWrapper: 'bg-white text-center transform -rotate-6',
  memberName: 'text-black text-xl mb-0 -mt-6 py-2',
  memberRoleWraper: 'mx-auto w-48 text-right',
  memberRole: 'pr-4 pb-2 pt-8 -mt-6',
  memberText: 'w-50 mx-auto text-center text-lg',
  memberQuotes: 'py-6 font-thin',
} /** } */

const GroupMember: React.FunctionComponent<GroupMemberProps> = ({
  name,
  description,
  role,
  quote,
  color,
  imgFixed,
}) => (
  <div className={classes.member}>
    <Img fluid={imgFixed} className={classes.memberImg} alt={name} />
    <div className={classes.memberNameWrapper}>
      <h3 className={classes.memberName}>{name}</h3>
    </div>
    <div className={classes.memberRoleWraper} style={{ background: color }}>
      <div className={classes.memberRole}>{role}</div>
    </div>
    <div className={classes.memberText}>
      <p className={classes.memberQuotes}>{quote}</p>
      <p>{description}</p>
    </div>
  </div>
)

export default GroupMember
