import { Link } from 'gatsby'
import Posts from '../Posts'
import React from 'react'
import Section from '../Section/Section'
import fr from '../../locales/fr'
import routes from '../../routes'

interface SectionPressProps {
  presses: readonly GatsbyTypes.PostFragment[]
  showOnIndexPage?: boolean
}

const classes = /** class={ */ {
  link: 'text-primary hover:underline text-sm md:text-base text-center',
} /** } */

const getJSONLD = (presses: readonly GatsbyTypes.PostFragment[]): string => {
  const pressesData = presses.map(press => {
    return {
      dateCreated: press.date,
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      author: fr.groupName,
      name: press.title,
      url: press.uri,
      description: press.excerpt,
    }
  })

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: pressesData,
  })
}

const SectionPress: React.FunctionComponent<SectionPressProps> = ({ presses, showOnIndexPage }) => (
  <Section title={fr.press} onlyOne={!showOnIndexPage}>
    <Posts posts={presses} showOnIndexPage={showOnIndexPage} postPath={routes.press} showDate={false} />
    {showOnIndexPage && (
      <div className="mt-6 text-center">
        <Link to={routes.press} className={classes.link}>
          {fr.showAllPresse}
        </Link>
      </div>
    )}
    <script type="application/ld+json">{getJSONLD(presses)}</script>
  </Section>
)

export default SectionPress
