import Img, { FluidObject } from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import React from 'react'
import Section from '../Section/Section'
import fr from '../../locales/fr'
import routes from '../../routes'

const classes = /** class={ */ {
  description: 'text-center md:text-xl lg:text-2xl',
  albumName: 'text-primary uppercase',
  img: 'my-10 md:my-12 lg:my-20',
  ctaWrapper: 'flex justify-center',
  cta: 'text-white bg-primary block rounded-full font-bold px-4 py-3 md:px-6 md:py-5 lg:px-8 lg:py-6  lg:text-lg ',
} /** } */

const SectionAlbum: React.FunctionComponent = () => {
  const data = useStaticQuery<GatsbyTypes.AlbumQueryQuery>(graphql`
    query AlbumQuery {
      fileName: file(relativePath: { eq: "amnesie-internationale-pochette.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Section title={fr.newAlbum} id="album">
      <p className={classes.description}>
        {fr.newAlbum}
        <span className={classes.albumName}>{` ${fr.albumName} `}</span>
        {fr.available}
      </p>
      <Img fluid={data.fileName?.childImageSharp?.fluid as FluidObject} className={classes.img} />
      <div className={classes.ctaWrapper}>
        <a href={routes.bandcamp} target="_blank" className={classes.cta}>
          {fr.listenOrBuy}
        </a>
      </div>
    </Section>
  )
}

export default SectionAlbum
