import Posts from '../Posts'
import { Link } from 'gatsby'
import React from 'react'
import Section from '../Section/Section'
import fr from '../../locales/fr'
import routes from '../../routes'

interface SectionActualitiesProps {
  actualities: readonly GatsbyTypes.PostFragment[]
  showOnIndexPage?: boolean
}

const classes = /** class={ */ {
  link: 'text-primary hover:underline text-sm md:text-base text-center',
} /** } */

const getJSONLD = (actualities: readonly GatsbyTypes.PostFragment[]): string => {
  const actualitiesData = actualities.map(actuality => {
    return {
      dateCreated: actuality.date,
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      author: fr.groupName,
      name: actuality.title,
      url: actuality.uri,
      description: actuality.excerpt,
    }
  })

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: actualitiesData,
  })
}

const SectionActualities: React.FunctionComponent<SectionActualitiesProps> = ({ actualities, showOnIndexPage }) => (
  <Section title={fr.actualities} onlyOne={!showOnIndexPage}>
    <Posts posts={actualities} showOnIndexPage={showOnIndexPage} postPath={routes.actualities} />
    {showOnIndexPage && (
      <div className="mt-6 text-center">
        <Link to={routes.actualities} className={classes.link}>
          {fr.showAllActualities}
        </Link>
      </div>
    )}
    <script type="application/ld+json">{getJSONLD(actualities)}</script>
  </Section>
)

export default SectionActualities
