import Img, { FluidObject } from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import React from 'react'
import fr from '../../locales/fr'

const classes = /** class={ */ {
  quotes: 'text-center pt-10 pb-4 md:pt-12 md:pb-5 lg:pt-16 lg:pb-6 text-lg md:text-2xl lg:text-4xl font-thin italic',
  author: 'text-center text-sm md:text-base lg:text-xl',
} /** } */

const Hero: React.FunctionComponent = () => {
  const data = useStaticQuery<GatsbyTypes.HeroQueryQuery>(graphql`
    query HeroQuery {
      fileName: file(relativePath: { eq: "hero-lbf.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3176) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <h1 className="sr-only">Lux bas-fond - Groupe Rock, poètes libertaires</h1>
      <Img fluid={data.fileName?.childImageSharp?.fluid as FluidObject} />
      <div className="container">
        <p className={classes.quotes}>{fr.quotes}</p>
        <p className={classes.author}>{fr.quotesAuthor}</p>
      </div>
    </>
  )
}
export default Hero
