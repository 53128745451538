import Hero from '../components/Hero'
import Layout from '../components/Layout'
import React from 'react'
import SectionAbout from '../components/SectionAbout'
import SectionActualities from '../components/SectionActualities'
import SectionAlbum from '../components/SectionAlbum'
import SectionConcert from '../components/SectionConcert'
import SectionPress from '../components/SectionPress'
import Seo from '../components/Seo'
import { graphql } from 'gatsby'

interface IndexPageProps {
  data: GatsbyTypes.IndexPageQuery
}

const IndexPage: React.FunctionComponent<IndexPageProps> = ({ data }) => (
  <Layout>
    <Seo />
    <Hero />
    <SectionAbout />
    <SectionAlbum />
    <SectionConcert concerts={data.concerts.nodes} showOnIndexPage />
    <SectionActualities actualities={data.actualities.nodes} showOnIndexPage />
    <SectionPress presses={data.presses.nodes} showOnIndexPage />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    concerts: allWpConcert(sort: { order: ASC, fields: acf___date }, filter: { acf: { alreadypassed: { eq: null } } }) {
      nodes {
        ...Concert
      }
    }
    actualities: allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "actualite" } } } } }
      limit: 3
    ) {
      nodes {
        ...Post
      }
    }
    presses: allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "presse" } } } } }
      limit: 3
    ) {
      nodes {
        ...Post
      }
    }
  }
`
