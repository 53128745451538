import { Link, graphql } from 'gatsby'

import React from 'react'
import fr from '../../locales/fr'
import parse from 'html-react-parser'

interface PostsProps {
  posts: readonly GatsbyTypes.PostFragment[]
  showOnIndexPage?: boolean
  postPath: string
  showDate?: boolean
}

const classes = /** class={ */ {
  list: 'list-none max-w-5xl mx-auto',
  article: 'pt-6',
  title: 'text-2xl md:text-3xl lg:text-4xl',
  excerpt: 'text-gray-300 my-4',
  link: 'text-primary hover:underline text-sm md:text-base',
} /** } */

const Posts: React.FunctionComponent<PostsProps> = ({ posts, postPath, showOnIndexPage, showDate = true }) => {
  return (
    <ol className={classes.list}>
      {posts.map(post => {
        return (
          <li key={post.uri}>
            <article className={classes.article}>
              <header>
                <Link to={postPath + post.uri}>
                  {showOnIndexPage ? (
                    <h3 className={classes.title}>{post.title}</h3>
                  ) : (
                    <h2 className={classes.title}>{post.title}</h2>
                  )}
                </Link>
                {showDate ? <small>{post.date}</small> : null}
              </header>
              <section className={classes.excerpt}>{parse(post.excerpt ?? '')}</section>
              <Link to={postPath + post.uri} className={classes.link}>
                {fr.readMore}
              </Link>
            </article>
          </li>
        )
      })}
    </ol>
  )
}

export default Posts

export const PostFragment = graphql`
  fragment Post on WpPost {
    id
    title
    date(formatString: "DD/MM/YYYY")
    excerpt
    uri
  }
`
