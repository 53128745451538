import { graphql, useStaticQuery } from 'gatsby'

import GroupMember from './GroupMember'
import React from 'react'
import Section from '../Section/Section'
import fr from '../../locales/fr'

const classes = /** class={ */ {
  description: 'text-center text-base lg:text-lg',
  grid: 'mt-12 flex flex-col items-center md:grid md:gap-3 md:grid-cols-2 lg:grid-cols-4 md:items-start',
  embedWrapper: 'w-full relative p-0  mt-24',
  embedIframe: 'absolute top-0 left-0 w-full h-full',
} /** } */

const SectionAbout: React.FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    fragment memberImg on File {
      childImageSharp {
        fluid(maxWidth: 199) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query MemberQuery {
      geraud: file(relativePath: { eq: "Geraud.png" }) {
        ...memberImg
      }
      julien: file(relativePath: { eq: "julien.jpg" }) {
        ...memberImg
      }
      lezard: file(relativePath: { eq: "Lezard.png" }) {
        ...memberImg
      }
      pat: file(relativePath: { eq: "Pat.png" }) {
        ...memberImg
      }
    }
  `)

  return (
    <Section title={fr.about}>
      <p className={classes.description}>{fr.aboutDesc1}</p>
      <p className={classes.description}>{fr.aboutDesc2}</p>
      <div className={classes.grid}>
        <GroupMember
          name={fr.members.geraud.name}
          role={fr.members.geraud.role}
          quote={fr.members.geraud.quote}
          description={fr.members.geraud.description}
          color="#d7963a"
          imgFixed={data.geraud.childImageSharp.fluid}
        />
        <GroupMember
          name={fr.members.julien.name}
          role={fr.members.julien.role}
          quote={fr.members.julien.quote}
          description={fr.members.julien.description}
          color="#b9197b"
          imgFixed={data.julien.childImageSharp.fluid}
        />
        <GroupMember
          name={fr.members.lezard.name}
          role={fr.members.lezard.role}
          quote={fr.members.lezard.quote}
          description={fr.members.lezard.description}
          color="#d7963a"
          imgFixed={data.lezard.childImageSharp.fluid}
        />
        <GroupMember
          name={fr.members.pat.name}
          role={fr.members.pat.role}
          quote={fr.members.pat.quote}
          description={fr.members.pat.description}
          color="#705e99"
          imgFixed={data.pat.childImageSharp.fluid}
        />
      </div>

      <div className={classes.embedWrapper} style={{ paddingTop: '56.17021276595745%' }}>
        <iframe
          className={classes.embedIframe}
          src="https://www.youtube.com/embed/uJXO47dAa2Y"
          scrolling="no"
          title="YouTube embed"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen={true}
        ></iframe>
      </div>
    </Section>
  )
}

export default SectionAbout
